import MainLayout from '/layouts/MainLayout';

export default function nofFound() {
    return (
        <div className="container min-h-screen">
            <div className="flex justify-center min-h-screen text-white text-7xl items-center">
                <h1>Хуудас олдсонгүй</h1>
            </div>
        </div>
    );
}

nofFound.layout = MainLayout;
